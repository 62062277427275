import './UserManager.css';
import logoGoogle from '../../../../assets/LogoGoogle.png';
import LogoMicrosoft from '../../../../assets/LogoMicrosoft.png';
import { useEffect, useState } from 'react';
import { db, database } from '../../../../firebase';
import { ref, onValue } from 'firebase/database';
import { collection, query, getDocs, doc, updateDoc } from 'firebase/firestore';
import Loading from '../../../Loading';

function UserManager() {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sortOrder, setSortOrder] = useState('asc');
    const [showOnlineUsers, setShowOnlineUsers] = useState(false); // Trạng thái bộ lọc

    const handleChangeRole = async (user) => {
        const valueOfRole = user.role === 'user' ? 'admin' : 'user';
        await updateDoc(doc(db, 'users', user.id), { role: valueOfRole });
        setUsers((prevUsers) => {
            return prevUsers.map((u) => {
                if (u.id === user.id) {
                    return { ...u, role: valueOfRole };
                }
                return u;
            });
        });
    };

    const extractName = (fullName) => {
        const parts = fullName.split(' ');
        if (parts.length > 0) {
            return parts[parts.length - 1];
        }
        return fullName;
    };

    const handleArrange = () => {
        const sortedUsers = [...users];
        sortedUsers.sort((a, b) => {
            const nameA = extractName(a.name);
            const nameB = extractName(b.name);
            if (sortOrder === 'asc') {
                return nameA.localeCompare(nameB);
            } else {
                return nameB.localeCompare(nameA);
            }
        });
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        setUsers(sortedUsers);
    };

    useEffect(() => {
        const fetchData = async () => {
            const q = query(collection(db, 'users'));
            const querySnapshot = await getDocs(q);
            let usersArray = [];
            querySnapshot.forEach((doc) => {
                usersArray.push({ ...doc.data(), id: doc.id });
            });

            onValue(ref(database, 'onlineUsers'), (data) => {
                const onlineUsers = data.val();
                const usersWithStatus = usersArray.map((user) => {
                    return {
                        ...user,
                        isOnline: onlineUsers[user.uid] ? true : false,
                    };
                });
                setUsers(usersWithStatus);
            });

            setLoading(false);
        };

        fetchData();
    }, []);

    const filteredUsers = showOnlineUsers
        ? users.filter((user) => user.isOnline)
        : users;

    return (
        <div className="User_Manager">
            <div className="User_Manager_Filter">
                <ion-icon name="filter"></ion-icon>
                <select id="filterDropdown" onChange={(e) => setShowOnlineUsers(e.target.value === 'online')}>
                    <option value="all">Tất cả</option>
                    <option value="online">Đang trực tuyến</option>
                </select>
            </div>
            <table className="User_Manager_Table">
                <thead>
                    <tr className="UsersTable_row UserTable_title">
                        <th>
                            <p>Họ và tên</p>
                            <ion-icon
                                name={sortOrder === 'asc' ? 'arrow-up-outline' : 'arrow-down-outline'}
                                onClick={handleArrange}
                            ></ion-icon>
                        </th>
                        <th>Email</th>
                        <th>UID</th>
                        <th className="td_align_center">Admin</th>
                    </tr>
                </thead>
                <tbody>
                    {loading ? (
                        <tr>
                            <td colSpan="3">
                                <Loading />
                            </td>
                        </tr>
                    ) : (
                        filteredUsers.map((user, index) => {
                            return (
                                <tr className="UsersTable_row" key={index}>
                                    <td>
                                        <div className="UsersTable_row_nameBox">
                                            <div>
                                                <img
                                                    src={
                                                        user.imgSrc
                                                            ? user.imgSrc
                                                            : 'https://cdn-icons-png.flaticon.com/512/8792/8792047.png'
                                                    }
                                                    alt=""
                                                />
                                                <span
                                                    className={
                                                        user.isOnline
                                                            ? 'user_active_status online_status'
                                                            : 'user_active_status'
                                                    }
                                                ></span>
                                            </div>
                                            <p>{user.name}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="UsersTable_row_emailBox">
                                            <img
                                                src={
                                                    user.authProvider === 'Google'
                                                        ? logoGoogle
                                                        : LogoMicrosoft
                                                }
                                                alt=""
                                            />
                                            <p>{user.email}</p>
                                        </div>
                                    </td>
                                    <td>
                                        {user.uid}
                                    </td>
                                    <td>
                                        <div key={user.id}>
                                            <input
                                                type="checkbox"
                                                id={`switch-${user.id}`}
                                                className="switch-input"
                                                defaultChecked={user.role === 'admin'}
                                                onChange={() => handleChangeRole(user)}
                                            />
                                            <label htmlFor={`switch-${user.id}`} className="switch"></label>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default UserManager;
