
import { useEffect } from "react";
import Course from "../../Course";
import "./courses.css"
import Footer from "../../Footer";

function Courses(props) {

    useEffect(() => {
        window.scrollTo(0, 0); // Đặt vị trí cuộn trang về đầu trang khi component được hiển thị
    }, []);

    const datas = [
        {
            title: "KIẾN THỨC NỀN TẢNG - TƯ DUY THIẾT KẾ",
            tag: "Tư duy thiết kế",
            imgSrc: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/fb1c2172121647.5bdc6ec3d2b51.jpg",
            description: "Các kiến thức cơ bản nhất về thiết kế như bố cục, màu sắc, căn chỉnh, typography,...",
            progress: 0,
            joined: 235,
            join: true
        },
        {
            title: "THIẾT KẾ MINH HOẠ VỚI ADOBE ILLUSTRATOR",
            tag: "Illustrator",
            imgSrc: "https://i.pinimg.com/originals/50/30/50/503050ae4753476a33e85fa43628bc25.jpg",
            description: "Các kiến thức cơ bản nhất về thiết kế như bố cục, màu sắc, căn chỉnh, typography,...",
            progress: 0,
            joined: 235,
            join: true
        },
        {
            title: "THIẾT KẾ, CHỈNH SỬA ẢNH VỚI ADOBE PHOTOSHOP",
            tag: "Photoshop",
            imgSrc: "https://cdn.dribbble.com/users/1757157/screenshots/13215181/media/002338a4a7859fc8e165767bfe4eba8a.jpg",
            description: "Các kiến thức cơ bản nhất về thiết kế như bố cục, màu sắc, căn chỉnh, typography,...",
            progress: 0,
            joined: 235,
            join: true
        },
        {
            title: "BIÊN TẬP VIDEO CƠ BẢN VỚI ADOBE PREMIERE",
            tag: "Premiere",
            imgSrc: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/5c334f72121647.5f23d04d729aa.jpg",
            description: "Các kiến thức cơ bản nhất về thiết kế như bố cục, màu sắc, căn chỉnh, typography,...",
            progress: 0,
            joined: 235,
            join: false
        },
        {
            title: "BIÊN TẬP VIDEO ĐƠN GIẢN VỚI CAPCUT (MOBILE)",
            tag: "Capcut",
            imgSrc: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/fb1c2172121647.5bdc6ec3d2b51.jpg",
            description: "Các kiến thức cơ bản nhất về thiết kế như bố cục, màu sắc, căn chỉnh, typography,...",
            progress: 100,
            joined: 235,
            join: false
        },
        {
            title: "KĨ XẢO CƠ BẢN VỚI ADOBE AFTER EFFECT",
            tag: "After Effect",
            imgSrc: "https://i.pinimg.com/originals/50/30/50/503050ae4753476a33e85fa43628bc25.jpg",
            description: "Các kiến thức cơ bản nhất về thiết kế như bố cục, màu sắc, căn chỉnh, typography,...",
            progress: 58,
            joined: 235,
            join: false
        },
        {
            title: "LÀM ANIMATION CƠ BẢN VỚI ADOBE ANIMATE",
            tag: "Animate",
            imgSrc: "https://cdn.dribbble.com/users/1757157/screenshots/13215181/media/002338a4a7859fc8e165767bfe4eba8a.jpg",
            description: "Các kiến thức cơ bản nhất về thiết kế như bố cục, màu sắc, căn chỉnh, typography,...",
            progress: 67,
            joined: 235,
            join: false

        },
        {
            title: "XỬ LÝ ÂM THANH CƠ BẢN VỚI ADOBE AUDITION",
            tag: "Audition",
            imgSrc: "https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/85516a121717591.60cb51fd592e9.jpg",
            description: "Các kiến thức cơ bản nhất về thiết kế như bố cục, màu sắc, căn chỉnh, typography,...",
            progress: 100,
            joined: 235,
            join: false
        }
    ]

    return (
        <div className="Courses">
            <div className="Container_courses">
                <div className="Container_courses_label">
                    <h3>KHOÁ HỌC ĐÃ THAM GIA</h3>
                    {/* Lấy ra số khoá học đã đăng ký */}
                    <span>{String(datas.filter(course => course.join === true).length).padStart(2, '0')}</span>
                </div>
                <div className="Courses_list">
                    {datas
                        .filter(course => course.join === true)
                        .map((course, index) => (
                            <Course data={course} key={index}/>
                        ))}
                </div>
            </div>
            <div className="Container_courses">
                <div className="Container_courses_label">
                    <h3>CÁC KHOÁ HỌC KHÁC</h3>
                    {/* Lấy ra số khoá học chưa đăng ký */}
                    <span>{String(datas.filter(course => course.join === false).length).padStart(2, '0')}</span>
                </div>
                <div className="Courses_list">
                    {datas
                        .filter(course => course.join === false)
                        .map((course, index) => (
                            <Course data={course} key={index} />
                        ))}
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Courses;