import { Link, useLocation } from 'react-router-dom';
import logo from "../../assets/LogoITC.png";
import LogoMicrosoft from "../../assets/LogoMicrosoft.png"
import LogoGoogle from "../../assets/LogoGoogle.png"
import "./index.css";
import { useState, useEffect } from 'react';
import { logout, auth, handleGetUserByUid, signInWithGoogle, signInWithMicrosoft, database } from "../../firebase"
import { onAuthStateChanged } from "firebase/auth";
import { ref, onDisconnect, set } from 'firebase/database';

function Header(props) {

    const [popupInfo, setPopupInfo] = useState(false);
    const [popupLogin, setPopupLogin] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);

    const location = useLocation();

    const handleLogout = async () => {
        try {
            await logout();
            // Khi đăng xuất, cập nhật trạng thái trực tuyến sang false
            if (currentUser) {
                const onlineStatusRef = ref(database, `onlineUsers/${currentUser.uid}`);
                set(onlineStatusRef, false);
            }
            setPopupInfo(false);
        } catch (err) {
            console.error(err);
        }
    }

    onAuthStateChanged(auth, (user) => {
        if (user) {
            const onlineStatusRef = ref(database, `onlineUsers/${user.uid}`);
            set(onlineStatusRef, true); 
            
            onDisconnect(onlineStatusRef).set(false);
        }
    });

    useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                const userInfo = await handleGetUserByUid(user.uid);
                const updatedUser = {
                    ...user,
                    role: userInfo === undefined ? "user" : userInfo.role,
                };
                setCurrentUser(updatedUser);
            } else {
                setCurrentUser(null);
            }
        });
    }, []);

    const handleLoginGoogle = async () => {
        try {
            await signInWithGoogle();
            setPopupLogin(false);
        } catch (err) {
            console.error(err);
        }
    };

    const handleLoginMicrosoft = async () => {
        try {
            await signInWithMicrosoft();
            setPopupLogin(false);
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <div className="Header">
            <Link className="Header_left" to="/">
                <img src={logo} alt="" />
                <span>CÂU LẠC BỘ TIN HỌC</span>
            </Link>
            <div className="Header_right">
                <ul>
                    <Link to="/" className={location.pathname === '/' ? 'active_tab' : ''}>
                        TRANG CHỦ
                    </Link>
                    <Link
                        to="/courses"
                        className={location.pathname.startsWith('/courses') ? 'active_tab' : ''}
                    >
                        KHOÁ HỌC
                    </Link>
                    <Link to="/resources" className={location.pathname === '/resources' ? 'active_tab' : ''}>
                        TÀI NGUYÊN
                    </Link>
                    {
                        currentUser ?
                            <>
                                <div className='Header_right_line'></div>
                                <div className='current_user_box' onClick={() => setPopupInfo(!popupInfo)}>
                                    {currentUser.photoURL ? <img src={currentUser.photoURL} alt='' /> : <ion-icon name="person"></ion-icon>}
                                    <span>{currentUser.displayName ? currentUser.displayName : "Người dùng"}</span>
                                    <ion-icon name="chevron-down"></ion-icon>
                                </div>
                                {popupInfo &&
                                    <>
                                        <div className='overplayForPopup' onClick={() => setPopupInfo(!popupInfo)}></div>
                                        <div className='current_user_option'>
                                            <div className='user_option_info'>
                                                {currentUser.photoURL ? <img src={currentUser.photoURL} alt='' /> : <ion-icon name="person"></ion-icon>}
                                                <div>
                                                    <span>{currentUser.displayName ? currentUser.displayName : "Người dùng"}</span>
                                                    <p>{currentUser.email}</p>
                                                </div>
                                            </div>
                                            {currentUser.role === "admin" ? <Link className='user_option_select' to='/admin' onClick={() => setPopupInfo(!popupInfo)}>
                                                <ion-icon name="settings-outline"></ion-icon>
                                                <span>Admin Dashboard</span>
                                            </Link> : ''}
                                            <div className='user_option_select' onClick={handleLogout}>
                                                <ion-icon name="log-out-outline"></ion-icon>
                                                <span>Đăng xuất</span>
                                            </div>
                                        </div>
                                    </>
                                }
                            </>
                            :
                            <>
                                <button id='login_button' onClick={() => setPopupLogin(!popupLogin)}>ĐĂNG NHẬP</button>
                                {popupLogin ? <>
                                    <div className='Login_popup'>
                                        <div className='overplayForLogin' onClick={() => setPopupLogin(!popupLogin)}></div>
                                        <div className='Login_popup_box'>
                                            <ion-icon name="close-outline" onClick={() => setPopupLogin(!popupLogin)}></ion-icon>
                                            <div className='Popup_Login_title'>
                                                <span>Đăng nhập vào</span>
                                                <h2>VŨ TRỤ DESIGN</h2>
                                            </div>
                                            <div className="Popup_Login_button">
                                                <button onClick={handleLoginGoogle}>
                                                    <img src={LogoGoogle} alt="" />
                                                    <span>Đăng nhập với Google</span>
                                                </button>
                                                <button onClick={handleLoginMicrosoft}>
                                                    <img src={LogoMicrosoft} alt="" />
                                                    <span>Đăng nhập với Microsoft</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </> : ''}
                            </>
                    }
                </ul>
            </div>
        </div>
    );
}

export default Header;
