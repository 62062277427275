
import { useEffect } from "react";
import "./resources.css"
import cover from "../../../assets/ResourcesCover.png"
import Footer from "../../Footer";

function Resources(props) {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="Resources">
            <div className="Resources_cover">
                <img src={cover} alt="" />
                <div className="Resources_cover_text">
                    <h1>SHARE BỘ PHẦN MỀM ADOBE 2023</h1>
                    <p>File cài đặt bộ phần mềm Adobe 2023 từ Câu lạc bộ Tin học TVU. Chỉ cần tải về và cài đặt là sử dụng được, không cần làm gì thêm.</p>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Resources;