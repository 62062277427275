
import './App.css';
import Home from './components/layouts/Home';
import { Routes, Route } from 'react-router-dom';
import Courses from './components/layouts/Courses';
import Resources from './components/layouts/Resources';
import Header from './components/Header';
import Admin from './components/layouts/Admin';

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/courses" element={<Courses />}/>
        <Route path="/resources" element={<Resources />}/>
        <Route path="/admin/*" element={<Admin />}/>
      </Routes>
    </div>
  );
}

export default App;
