import { Link } from 'react-router-dom';
import logo from "../../../../assets/LogoITC.png"
import { useLocation } from 'react-router-dom';
import './nav.css'

function Nav() {

    const location = useLocation();

    return (
        <div className="Left_Nav">
            <div>
                <div className='Left_Nav_Title'>
                    <h4> Admin Dashboard </h4>
                    <h2>VŨ TRỤ DESIGN</h2>
                </div>
                <Link to="/admin" className={(location.pathname === '/admin' || location.pathname === '/admin/') ? 'Left_Nav_link nav_active' : 'Left_Nav_link'}>
                    <ion-icon name="podium-outline"></ion-icon>
                    <span>Tổng quan</span>
                </Link>
                <Link to="user-manager" className={location.pathname === '/admin/user-manager' ? 'Left_Nav_link nav_active' : 'Left_Nav_link'}>
                    <ion-icon name="person-outline"></ion-icon>
                    <span>Quản lý học viên</span>
                </Link>
                <Link to="course-manager" className={location.pathname === '/admin/course-manager' ? 'Left_Nav_link nav_active' : 'Left_Nav_link'}>
                    <ion-icon name="layers-outline"></ion-icon>
                    <span>Quản lý khoá học</span>
                </Link>
            </div>

            <div className="Footer_left LeftNav_footer">
                <span className="Footer_left--line1">Dự án được phát triển bởi</span>
                <div>
                    <img src={logo} alt="" />
                    <span>CÂU LẠC BỘ TIN HỌC</span>
                </div>
                <p>Copyright © by ITC 2023. All rights reserved.</p>
            </div>
        </div>

    )
}

export default Nav;