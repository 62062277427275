
import "./course.css"

function Course(props) {

    const {data} = props;

    return (
        <div className="Course">
            <div className="Course_img">
                <img src={data.imgSrc} alt="" />
            </div>
            <div className="Course_text">
                <div className="Course_text_top">
                    <span className="Course_text--tag">{data.tag}</span>
                    <h3>{data.title}</h3>
                    <p>{data.description}</p>
                </div>
                <div className="Course_text_bottom">
                    <div>
                        <ion-icon name="people"></ion-icon>
                        <span>{data.joined}</span>
                    </div>
                    <span>{data.progress}%</span>
                </div>
            </div>
        </div>
    )
}

export default Course;