import "./home.css"
import CoverImg from "../../../assets/HomeBanner1.png"
import LogoHSV from "../../../assets/LogoHSV.png"
import LogoTVU from "../../../assets/LogoTVU.png"
import LogoITC from "../../../assets/LogoITC.png"
import BeImg1 from "../../../assets/benefit1.png"
import BeImg2 from "../../../assets/benefit2.png"
import BeImg3 from "../../../assets/benefit3.png"
import { Link } from "react-router-dom";
import { useEffect } from "react";
import Footer from "../../Footer"
// import { db } from "../../../firebase"
// import {
//     collection,
//     query,
//     onSnapshot
// } from "firebase/firestore";

function Home(props) {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // useEffect(() => {
    //     const q = query(collection(db, "users"));
    //     const unsub = onSnapshot(q, (querySnapshot) => {
    //         let usersArray = [];
    //         querySnapshot.forEach((doc) => {
    //             usersArray.push({ ...doc.data(), id: doc.id });
    //         });
    //     });
    //     return () => unsub();
    // }, []);

    return (
        <div className="Home">
            <div className="Home_cover">
                <img src={CoverImg} alt="" />
                <div className="Home_cover_right">
                    <div className="Cover_right_logo">
                        <img src={LogoHSV} alt="" />
                        <img src={LogoTVU} alt="" />
                        <img src={LogoITC} alt="" />
                    </div>
                    <div className="Cover_right_text">
                        <span>Khám phá</span>
                        <h1>"VŨ TRỤ DESIGN"</h1>
                        <p>CÙNG CÂU LẠC BỘ TIN HỌC</p>
                    </div>
                    <Link to="/courses">
                        <span>Tham gia ngay</span>
                        <ion-icon name="arrow-forward-outline"></ion-icon>
                    </Link>
                </div>
            </div>
            <div className="Home_benefit">
                <h3>Lợi ích của học viên</h3>
                <div className="Home_benefit_container">
                    <div className="HBe_container--item scalex09">
                        <div className="HBe_container--item_img">
                            <img src={BeImg2} alt='' />
                        </div>
                        <div className="HBe_container--item_text">
                            <h4>COMBO KHOÁ HỌC<br />DESIGN CHẤT LƯỢNG</h4>
                            <p>Sở hữu ngay Combo khoá học Design từ giảng viên FPT Arena. Tổng hợp mọi kiến thức từ cơ bản đến nâng cao.</p>
                        </div>
                    </div>
                    <div className="HBe_container--item">
                        <div className="HBe_container--item_img">
                            <img src={BeImg1} alt='' />
                        </div>
                        <div className="HBe_container--item_text">
                            <span>Ứng tuyển</span>
                            <h4>VÀO VỊ TRÍ DESIGNER<br />TRƯỜNG ĐẠI HỌC TRÀ VINH</h4>
                            <p>Được tham gia vào quá trình xây dựng các chương trình, sự kiện,... tại Trường Đại học Trà Vinh với vai trò Designer. Qua đó, hình thành các kỹ năng làm việc nhóm, quản lý thời gian,...</p>
                        </div>
                    </div>
                    <div className="HBe_container--item scalex09">
                        <div className="HBe_container--item_img">
                            <img src={BeImg3} alt='' />
                        </div>
                        <div className="HBe_container--item_text">
                            <h4>BỘ PHẦN MỀM ADOBE<br />CÀI ĐẶT MIỄN PHÍ</h4>
                            <p>Được sự hỗ trợ từ Câu lạc bộ Tin học trong quá trình cài đặt phần mềm.</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default Home;