import "./index.css"
import logo from "../../assets/LogoITC.png"
import logoFB from "../../assets/LogoFb.png"
import logoZalo from "../../assets/LogoZalo.png"
import {Link} from 'react-router-dom';

function Footer() {
    return (
        <div className="Footer">
            <div className="Footer_left">
                <span className="Footer_left--line1">Dự án được phát triển bởi</span>
                <div>
                    <img src={logo} alt="" />
                    <span>CÂU LẠC BỘ TIN HỌC</span>
                </div>
                <span>TRỰC THUỘC HỘI SINH VIÊN TRƯỜNG ĐẠI HỌC TRÀ VINH</span>
                <p>Copyright © by ITC 2023. All rights reserved.</p>
            </div>
            <div className="Footer_right">
                <div className="Footer_right--title">
                    <span>THEO DÕI</span>
                    <span>CÂU LẠC BỘ TIN HỌC</span>
                </div>
                <Link className="Footer_right--follow" to="https://www.facebook.com/clbtinhoctvu" target="_blank">
                    <span>Fanpage</span>
                    <img src={logoFB} alt="" />
                </Link>
                <Link className="Footer_right--follow" to="https://zalo.me/g/ufjevu506" target="_blank">
                    <span>Zalo Group</span>
                    <img src={logoZalo} alt="" />
                </Link>
            </div>
        </div>
    );
}

export default Footer;