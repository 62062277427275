
import { useEffect } from "react";
import "./admin.css"
import Nav from "./Nav";
import UserManager from "./UserManager";
import { Routes, Route } from "react-router-dom";
import Overview from "./Overview";
import CourseManager from "./CourseManager";

function Admin(props) {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="Admin_Dashboard">
            <Nav />
            <Routes>
                <Route path="/" element={<Overview />} />
                <Route path="user-manager" element={<UserManager />} />
                <Route path="course-manager" element={<CourseManager />} />
            </Routes>
        </div>
    )
}

export default Admin;