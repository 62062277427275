// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider, OAuthProvider, signInWithPopup, signOut } from 'firebase/auth'
import { collection, addDoc, where, query, getDocs } from "firebase/firestore";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyC-DJFZL2X8cK6RlcJkpzuWzOtXR0pEFtk",
  authDomain: "chat-app-5b28c.firebaseapp.com",
  projectId: "chat-app-5b28c",
  storageBucket: "chat-app-5b28c.appspot.com",
  messagingSenderId: "719770333785",
  appId: "1:719770333785:web:4e6c386cff9a17a0273748",
  measurementId: "G-K037YRJ4YC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const db = getFirestore(app);
const auth = getAuth(app);

// Add New User To Firestore
const handleAddUserToDB = async (user, authProvider) => {
  const q = query(collection(db, "users"), where("uid", "==", user.uid));
  const docs = await getDocs(q);
  if (docs.docs.length === 0) {
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name: user.displayName,
      imgSrc: user.photoURL,
      authProvider: authProvider,
      email: user.email,
      role: "user"
    });
  }
}

// Get user by uid
const handleGetUserByUid = async (_uid) => {
  const q = query(collection(db, "users"), where("uid", "==", _uid));
  const querySnapshot = await getDocs(q);
  let userArray = [];
  querySnapshot.forEach((doc) => {
    userArray.push({ ...doc.data(), id: doc.id });
  });
  return userArray[0];
}

// Sign in with Google
const signInWithGoogle = async () => {
  const googleProvider = new GoogleAuthProvider();
  try {
    const res = await signInWithPopup(auth, googleProvider);
    await handleAddUserToDB(res.user, "Google");
    return res.user;
  } catch (err) {
    console.error(err);
  }
};

// Sign in with Microsoft
const signInWithMicrosoft = async () => {
  const microsoftProvider = new OAuthProvider('microsoft.com');
  try {
    const res = await signInWithPopup(auth, microsoftProvider);
    await handleAddUserToDB(res.user, "Microsoft");
    return res.user;
  } catch (err) {
    console.error(err);
  }
};

const logout = () => {
  signOut(auth);
};

export { auth, db, signInWithGoogle, logout, signInWithMicrosoft, handleGetUserByUid, database };